import React from 'react'
import Header from "./Header";
import Footer from "./Footer";
import styled from "styled-components";
import whoWeAreImage from '../assets/undraw_experts3_3njd.svg'


const yellow = '#FFC734'
const lightYellow = '#FFEAB2'

const IntroSection = styled.div`
  margin: 4rem auto;
  width: 100%;
  min-height: 78vh;
`

const WidthHolder = styled.div`
  max-width: 1280px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  
  @media only screen and (max-width: 768px) {
    flex-direction: column;  
  }
`

const LeftSection = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: 6rem auto 4rem 0;
  
  &:first-child {
      margin-top: 1rem;
  }
    
  @media only screen and (max-width: 768px) {
    margin: 1rem 0 0 0;
  }
`

const RightSection = styled.div`
  width: 100%;
  max-width: 40rem;
  margin: 6rem 0 4rem auto;
  
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const Title = styled.h1`
  font-size: 3rem;
`

const TitleSection = styled.h3`
  font-size: 2.25rem;
`

const Content = styled.p`
  font-size: 1.125rem;
  max-width: 40rem;
  width: 100%;
`

const Highlight = styled.span`
  background: ${props => props.elColor};
  color: #000;
  margin: 0 1rem 0.5rem 0;
  padding: 0.5rem 0.75rem;
  white-space: nowrap;
  display: inline-block;
  
  span {
    width: ${props => props.widthType === 'full' ? '100%' : 'auto'};
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    background: #fff;
  }
`


const WhoWeAreImage = styled.img`
  width: 100%;
  max-width: 40rem;
  margin: auto 0;
`


const ProjectList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const ProjectItem = styled.div`
  width: 45%;
  margin-bottom: 3rem;
  
  @media only screen and (max-width: 768px) {
    width: 100%;  
  }
`


const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  
  @media only screen and (max-width: 768px) {
    display: none;  
  }
`

const ProjectDesc = styled.p`
  font-size: 1rem;
`

class MarkoKosmacCVComponent extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="page">
        <Header/>
        <IntroSection>
          <WidthHolder>
            <LeftSection>
              <Title>Marko Kosmac</Title>
              <Content><strong>Senior JavaScript developer</strong>. I have experience in consulting and leading teams, with a strong focus on achieving targets. </Content>
              <Content>Proficient with  web solutions build on modern frameworks like React and Angular.</Content>
              <Content>GitHub: https://github.com/marko-kosmac</Content>
              <Content>LinkedIn: https://www.linkedin.com/in/marko-kosmač-14648291/</Content>
            </LeftSection>
            <RightSection>
              <WhoWeAreImage src={whoWeAreImage}/>
            </RightSection>
          </WidthHolder>
          <WidthHolder>
            <LeftSection>
              <Content>
                <TitleSection>Key skills</TitleSection>
                <Highlight elColor={yellow}>JavaScript ES6+</Highlight>
                <Highlight elColor={yellow}>React</Highlight>
                <Highlight elColor={yellow}>Node</Highlight>
                <Highlight elColor={yellow}>AWS</Highlight>
                <Highlight elColor={yellow}>CSS</Highlight>
                <Highlight elColor={yellow}>HTML 5</Highlight>
                <Highlight elColor={yellow}>rest APIs</Highlight>
                <Highlight elColor={yellow}>Databases</Highlight>
                <Highlight elColor={yellow}>Infrastructure</Highlight>
                <Highlight elColor={lightYellow}>Redux</Highlight>
                <Highlight elColor={lightYellow}>Webpack</Highlight>
                <Highlight elColor={lightYellow}>MySQL</Highlight>
                <Highlight elColor={lightYellow}>DynamoDB</Highlight>
                <Highlight elColor={lightYellow}>RDS</Highlight>
                <Highlight elColor={lightYellow}>Ubuntu</Highlight>
                <Highlight elColor={lightYellow}>Bash</Highlight>
                <Highlight elColor={lightYellow}>GIT</Highlight>
                <Highlight elColor={lightYellow}>CI/CD</Highlight>
                <Highlight elColor={lightYellow}>NPM</Highlight>
                <Highlight elColor={lightYellow}>Styled Components</Highlight>
                <Highlight elColor={lightYellow}>SCSS</Highlight>
              </Content>

              <TitleSection>Key experience</TitleSection>
              <Content>
                <Highlight elColor={yellow} widthType={'full'}>Front end development <span>10 years</span></Highlight>
                <Highlight elColor={yellow} widthType={'full'}>Backend development <span>8 years</span></Highlight>
                <Highlight elColor={yellow} widthType={'full'}>Project management <span>6 years</span></Highlight>
              </Content>

              <TitleSection>Programing languages</TitleSection>
              <Content>
                <Highlight elColor={yellow}>Javascript <span>8 years</span></Highlight>
                <Highlight elColor={yellow}>PHP <span>6 years</span></Highlight>
                <Highlight elColor={yellow}>HTML <span>10 years</span></Highlight>
                <Highlight elColor={yellow}>CSS <span>10 years</span></Highlight>
                <Highlight elColor={yellow}>MySQL <span>8 years</span></Highlight>
              </Content>

              <TitleSection>Frameworks & libraries</TitleSection>
              <Content>
                <Highlight elColor={yellow}>NodeJS <span>3 years</span></Highlight>
                <Highlight elColor={yellow}>ReactJS <span>2 years</span></Highlight>
                <Highlight elColor={yellow}>Angular <span>1 year</span></Highlight>
                <Highlight elColor={yellow}>jQuery <span>6 years</span></Highlight>
                <Highlight elColor={yellow}>AWS <span>3 years</span></Highlight>
                <Highlight elColor={yellow}>Wordpress <span>6 years</span></Highlight>
              </Content>



              <TitleSection>Latest Projects</TitleSection>

              <ProjectList>

                <ProjectItem>
                  <ProjectTitle>Telenor Denmark</ProjectTitle>
                  <Highlight elColor={yellow}>Front end developer</Highlight>
                  <ProjectDesc>Telenor Denmark has done redesign of their web pages.
                    <br/>I've helped them to implement new
                    design into pixel perfect front end for MyTelenor user interface.</ProjectDesc>
                  <ProjectDesc>
                    <Highlight elColor={lightYellow}>JavaScript ES6+</Highlight>
                    <Highlight elColor={lightYellow}>HTML 5</Highlight>
                    <Highlight elColor={lightYellow}>SCSS</Highlight>
                    <Highlight elColor={lightYellow}>.NET</Highlight>
                  </ProjectDesc>
                </ProjectItem>

                <ProjectItem>
                  <ProjectTitle>Sony - Criotive</ProjectTitle>
                  <Highlight elColor={yellow}>Front end developer</Highlight>
                  <ProjectDesc>Collaboration project IoT service of provisioning security locks & keys</ProjectDesc>
                  <ProjectDesc>
                    <Highlight elColor={lightYellow}>JavaScript ES6+</Highlight>
                    <Highlight elColor={lightYellow}>React</Highlight>
                    <Highlight elColor={lightYellow}>Redux</Highlight>
                    <Highlight elColor={lightYellow}>Webpack</Highlight>
                    <Highlight elColor={lightYellow}>Node</Highlight>
                    <Highlight elColor={lightYellow}>AWS</Highlight>
                    <Highlight elColor={lightYellow}>HTML 5</Highlight>
                    <Highlight elColor={lightYellow}>CSS</Highlight>
                    <Highlight elColor={lightYellow}>Styled Components</Highlight>
                  </ProjectDesc>
                </ProjectItem>

                <ProjectItem>
                  <ProjectTitle>Comsys - sizing tool</ProjectTitle>
                  <Highlight elColor={yellow}>Solution architect</Highlight>
                  <ProjectDesc>Comsys developed internal tool to support B2B sales decision process by selecting the
                    optimal device for clients needs based on various input scenarios and values.
                    <br/>I helped them by creating a web application using same complex algorithms and implemented easy
                    to use front end interface.
                  </ProjectDesc>
                  <Highlight elColor={lightYellow}>JavaScript ES6+</Highlight>
                  <Highlight elColor={lightYellow}>React</Highlight>
                  <Highlight elColor={lightYellow}>Redux</Highlight>
                  <Highlight elColor={lightYellow}>Webpack</Highlight>
                  <Highlight elColor={lightYellow}>Node</Highlight>
                  <Highlight elColor={lightYellow}>DynamoDB</Highlight>
                  <Highlight elColor={lightYellow}>AWS</Highlight>
                  <Highlight elColor={lightYellow}>CSS</Highlight>
                  <Highlight elColor={lightYellow}>HTML 5</Highlight>
                  <Highlight elColor={lightYellow}>Jenkins CD</Highlight>
                </ProjectItem>

                <ProjectItem>
                  <ProjectTitle>Sony - Reportex</ProjectTitle>
                  <Highlight elColor={yellow}>Full stack developer</Highlight>
                  <ProjectDesc>Reportex is a web application editor that does text to speech conversion with editing
                    audio content in real time.
                    <br/>I have helped them to release early beta product working on React and front end implementation
                    in a small agile team.
                  </ProjectDesc>
                  <Highlight elColor={lightYellow}>JavaScript ES6+</Highlight>
                  <Highlight elColor={lightYellow}>React</Highlight>
                  <Highlight elColor={lightYellow}>Redux</Highlight>
                  <Highlight elColor={lightYellow}>Webpack</Highlight>
                  <Highlight elColor={lightYellow}>Node</Highlight>
                  <Highlight elColor={lightYellow}>AWS</Highlight>
                  <Highlight elColor={lightYellow}>CSS</Highlight>
                  <Highlight elColor={lightYellow}>HTML 5</Highlight>
                  <Highlight elColor={lightYellow}>Styled Components</Highlight>
                  <Highlight elColor={lightYellow}>Unit testing</Highlight>
                </ProjectItem>
              </ProjectList>

              <TitleSection>Work experience</TitleSection>
              <Content>

              </Content>
            </LeftSection>
          </WidthHolder>
        </IntroSection>
        <Footer/>
      </div>
    )
  }
}

export default MarkoKosmacCVComponent
