import React from 'react'
import Header from './Header'
import Footer from './Footer'
import styled from 'styled-components'
import {Redirect} from 'react-router'
import createHistory from 'history/createBrowserHistory'
import {Helmet} from 'react-helmet'
import introImage from '../assets/marko-intro.png'
import WhatWeDoSvg from '../assets/alex-intro-services.png'
import WhoWeAreSvg from '../assets/marko-intro-projects.png'
import DanskeImage from '../assets/projects/danske.jpg'
import mobileLogo from "../assets/KamafaiLogo.svg";
import logo from "../assets/KamafaiLogoText.svg";
import Projects from '../assets/alex-intro.png'
import Html5Image from "../assets/projects/html5.png";
import Css3Image from "../assets/projects/css3.png";
import JSImage from "../assets/projects/js.png";
import ReactImage from "../assets/projects/react.png";
import NodeImage from "../assets/projects/node.png";
import AWSImage from "../assets/projects/aws.png";
import JenkinsImage from "../assets/projects/jenkins.svg";
import PostgresImage from "../assets/projects/postgres.png";
import SonyImage from '../assets/projects/sony.jpg'
import TelenorImage from '../assets/projects/telenor.jpg'
import HappyEarsImage from '../assets/projects/happyears.jpg'
import TheConferenceImage from '../assets/projects/theconference.jpg'
import MediaEvolutionImage from '../assets/projects/media-evolution.jpg'



const history = createHistory()

const IntroSection = styled.div`
  margin-top: 4rem;
  width: 100%;
  
  @media only screen and (max-width: 768px) {
  }
`

const WidthHolder = styled.div`
  max-width: 1280px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
    min-height: 85vh;
  align-items: center;
  flex-direction: row;
  
  @media only screen and (max-width: 768px) {
    justify-content: center;
    flex-direction: column;

  }
`

const WidthHolderProjects = styled.div`
  max-width: 1280px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  min-height: 55vh;
  align-items: center;
  flex-direction: row;
  
  @media only screen and (max-width: 768px) {
    justify-content: center;
    flex-direction: column;

  }
`

const WidthHolderReverse = styled.div`
  max-width: 1280px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  min-height: 85vh;
  align-items: center;
  flex-direction: row-reverse;
  
  @media only screen and (max-width: 768px) {
    flex-direction: ${props => props.reverseCol ? 'column-reverse' : 'column'};  
    justify-content: center;
  }
`

const LogoContainer = styled.div`
  justify-content: left;
  display: flex;
  margin: auto 0;
  height: 1.25rem;
  justify-content: start;
  width: 12rem;
  
  @media only screen and (max-width: 768px) {
    height: 1.5rem;
    margin: 1.25rem 0;  
    flex-direction: row;
    width: 100%;
  }
`

const BreakingLine = styled.div`
  transform: rotate(${props => props.tilt === 'down' ? '3' : '-3'}deg);
  border-bottom: 4px solid #f2c94c;
`

const IntroWhatWeDo = styled.div`
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`

const IntroWhoWeAre = styled.div`
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 50vh;
  position: relative;
  overflow: hidden;
  margin-top: 4rem;
`

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin: auto 0;
  
  @media only screen and (max-width: 768px) {
    font-size: 1.75rem;
    margin: 2rem 0;  
  }
`

const IntroTextContainer = styled.div`
  margin: 5rem 0;
  width:  30rem;
  max-width: 100%;
  
`


const WhatWeDoSlogan = styled.div`
  margin: auto 0;
  width: 30rem;
  max-width: 100%;
  
  @media only screen and (max-width: 768px) {
    text-align: center;
    margin: 0;
  }
`



const LogoMainText = styled.p`
  margin: 2rem 0;
  color: #000;
  font-weight: 300;
  line-height: 1.3;
  font-size: 1.75rem;
  
  @media only screen and (max-width: 768px) {
  font-size: 1.5rem;
  padding-top: 2rem;
}
`

const WhatWeDoContent = styled.p`
    font-size: 1.125rem;
    color: rgb(68, 68, 68);
    font-weight: 300;
    line-height: 1.3;
    margin: 1.5rem 0;
    line-height: 1.6;

`

const WhatWeDoImage = styled.img`
  max-width: 40rem;
      
  @media only screen and (max-width: 768px) {
    width: 80%;
    margin: 4rem auto 4rem auto;  
}
`

const WhatWeDoImageIntro = styled.img`
  max-width: 40rem;
  margin: 8rem 0 10rem 0;
      
  @media only screen and (max-width: 768px) {
    width: 80%;
    margin: auto;
    margin-bottom: 6rem;
  }
`

const WhatWeDoImageServices = styled.img`
  max-width: 40rem;
  margin: 8rem 0 10rem 0;
      
  @media only screen and (max-width: 768px) {
    width: 80%;
    margin: auto;
  }
`

const ActionButtonContainer = styled.div`
  display: flex;
  
  @media only screen and (max-width: 768px) {
  justify-content: center;
`

const ActionButton = styled.button`
  background: none;
  border: none;
  color: #f2c94c;
  padding: 0;
  line-height: 1;
  font-size: 4rem;
  cursor: pointer;
  transition: color 0.3s ease-out;
  
  &:hover{
    color: #000;
  }
`

const ActionButtonText = styled.p`
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    line-height: 1.2;
    border: none;
    font-size: 1.75rem;
    margin: auto 0;
    cursor: pointer;
    transition: color 0.3s ease-out;
    color: #000
    
    @media only screen and (max-width: 768px) {
    font-size: 1.5rem
  }
`

/*const MobileLogo = styled.img`
    display: block;
    height: 4rem;
    margin-left: 1rem;
    padding-top: 1.5rem;
`*/

const Logo = styled.img`
  cursor: pointer;
  height: 7rem;
  margin: -3rem auto;
  max-width: 20rem;
    
  @media only screen and (max-width: 768px) {
    padding-left: 1rem;
    margin: auto;
    width: 13rem;
  }
`

const IntroProjects = styled.div`
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 50vh;
  position: relative;
  overflow: hidden;
  margin-top: 4rem;
`

const ProjectImageTechnologiesContainer = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: end;
    flex-direction: row;
    padding: 1rem;
    flex-basis: auto;
    margin: auto 0;
    max-width: 100%;
    flex-wrap: wrap;



  
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const ProjectImageTechnologies = styled.img`
    margin: 6rem 0 0 0;
    box-sizing: border-box;
    object-fit: contain;
    max-width: 20%;
    min-width: 20%;
    max-height: 2rem;
    filter: grayscale(100%);
    opacity: 0.4;
    padding-right: 1rem;
  
  @media only screen and (max-width: 768px) {
    box-sizing: border-box;
    display: none;
  }
`

class HomeComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectNavigation: '',
      anchor: 0,
    }
    this.clickNavigation = this.clickNavigation.bind(this)
    this.clickAnchor = this.clickAnchor.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  clickNavigation(newUrl) {
    const location = history.location
    console.log(location.pathname, newUrl)
    if (location.pathname !== newUrl) {
      history.push({
        pathname: `${newUrl}`,
      })
      this.setState({
        redirectNavigation: newUrl,
      })
    }
  }

  clickAnchor() {
    console.log(this.state.anchor+1)
  }

  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Kamafai - hemsidor & mobilappar</title>
          <meta name="keywords" content="hemsidor, hemsida, mobilapp, mobilapplikation, iOS, Android, Wordpress" />
        </Helmet>
        <Header/>
        <IntroSection>
          <WidthHolder reverseCol={false}>
            <WhatWeDoSlogan>
              <IntroTextContainer>
                <LogoContainer>
                  {/*<MobileLogo onClick={this.clickNavigation.bind(this,'/')} src={mobileLogo} /> */}
                  <Logo onClick={this.clickNavigation.bind(this,'/')} src={logo} />
                </LogoContainer>
                <LogoMainText>Hemsidor & mobilappar
                <br/>est. 2017</LogoMainText>
              </IntroTextContainer>
            </WhatWeDoSlogan>
            <WhatWeDoImageIntro src={introImage} />
          </WidthHolder>
        </IntroSection>
        <BreakingLine/>

        <IntroWhoWeAre>
            <WidthHolderReverse reverseCol={false}>
            <WhatWeDoSlogan>
              <SectionTitle>Om oss</SectionTitle>
              <WhatWeDoContent>Vi är en duo programmerare som gillar att koda.
                <br/>Små och stora projekt.
              </WhatWeDoContent>
              <ActionButtonContainer>
                <ActionButtonText onClick={this.clickNavigation.bind(this,'/who-we-are')}>läs mer</ActionButtonText>
                <ActionButton onClick={this.clickNavigation.bind(this,'/who-we-are')}> &nbsp; &#8594;</ActionButton>
              </ActionButtonContainer>
            </WhatWeDoSlogan>
            <WhatWeDoImage src={Projects} />
          </WidthHolderReverse>
        </IntroWhoWeAre>
        <BreakingLine tilt='down'/>

        <IntroProjects>
          <WidthHolder reverseCol={false}>
            <WhatWeDoSlogan>
              <SectionTitle>Våra projekt</SectionTitle>
              <WhatWeDoContent>Ta en titt på hemsidor och mobilappar vi har byggt.
              </WhatWeDoContent>
              <ActionButtonContainer>
                <ActionButtonText onClick={this.clickNavigation.bind(this,'/projects')}>läs mer</ActionButtonText>
                <ActionButton onClick={this.clickNavigation.bind(this,'/projects')}> &nbsp; &#8594;</ActionButton>
              </ActionButtonContainer>
                <ProjectImageTechnologies src={SonyImage}/>
                <ProjectImageTechnologies src={TelenorImage}/>
                <ProjectImageTechnologies src={DanskeImage}/>
                <ProjectImageTechnologies src={HappyEarsImage}/>
                <ProjectImageTechnologies src={TheConferenceImage}/>
            </WhatWeDoSlogan>
            <WhatWeDoImage src={WhoWeAreSvg} />
          </WidthHolder>
        </IntroProjects>
        <ProjectImageTechnologiesContainer>

        </ProjectImageTechnologiesContainer>
        <BreakingLine tilt='up'/>

        <IntroWhatWeDo>
          <WidthHolderReverse reverseCol={false}>
            <WhatWeDoSlogan>
              <SectionTitle>Våra tjänster</SectionTitle>
              <WhatWeDoContent>Hemsidor och mobilappar.
                <br/>
                Vi gör det mesta inom webbutveckling.</WhatWeDoContent>
                <ActionButtonContainer>
                  <ActionButtonText onClick={this.clickNavigation.bind(this,'/what-we-do')}>läs mer</ActionButtonText>
                  <ActionButton onClick={this.clickNavigation.bind(this,'/what-we-do')}> &nbsp; &#8594;</ActionButton>
                </ActionButtonContainer>
              </WhatWeDoSlogan>
            <WhatWeDoImage src={WhatWeDoSvg} />
          </WidthHolderReverse>
        </IntroWhatWeDo>


        <Footer/>
        {this.state.redirectNavigation && <Redirect to={`${this.state.redirectNavigation}`} />}
      </div>
    )
  }
}

export default HomeComponent
