import React from 'react'
import styled from 'styled-components'
import {Redirect} from 'react-router'
import facebookIcon from '../assets/facebook.svg'
import linkedinIcon from '../assets/linkedin.svg'
import instagramIcon from '../assets/instagram.svg'
import createHistory from 'history/createBrowserHistory'
import logo from "../assets/KamafaiLogoText.svg";

const history = createHistory()

const FooterDiv = styled.div`
  background: #000;
  width: 100%;
  margin-top: 4rem;
  
  @media only screen and (max-width: 768px) {
    height: auto;  
  }
`

const WidthHolder = styled.div`
  width: 94%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  
  @media only screen and (max-width: 768px) {
    flex-direction: column;
}
`

const FooterNavigation = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  
  @media only screen and (max-width: 768px) {
    flex-direction: column;  
    margin-left: auto;
    margin-right: auto;
  }
`

const FooterSocials = styled.div`
  display: flex;
  
  @media only screen and (max-width: 768px) {
    margin: 0 0 1rem 0;  
  }
`

const NavButton = styled.div`
  font-size: 1rem;
  color: #fff;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 2rem;
  cursor: pointer;
  
  a {
    text-decoration: none;
    position: relative;
    color: #fff
      
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all .5s cubic-bezier(1,.25,0,.75) 0s;
    transition: all .5s cubic-bezier(1,.25,0,.75) 0s;
    }
    
    &:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    }

  
  @media only screen and (max-width: 768px) {
    line-height: 1.5;
    margin: 1rem 0 0 0 ;  
  }
`

const SocialIcon = styled.img`
  filter: invert(100%);
  width: 10%;
  margin-left: 13rem;
  
    @media only screen and (max-width: 768px) {
    display: none; 
  }
  
`

const Company = styled.p`
  font-size: 1rem;
  color: #fff;
  padding: 0;
  margin: 1.375rem auto; 
  font-family: 'Raleway', sans-serif;
  line-height: 2rem;
  

  
  a {
  text-decoration: none;
  position: relative;
  color: #fff
    
      
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all .5s cubic-bezier(1,.25,0,.75) 0s;
    transition: all .5s cubic-bezier(1,.25,0,.75) 0s;
    }
    
    &:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    }
  
  @media only screen and (max-width: 768px) {
    line-height: 1.5;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectNavigation: '',
    }
    this.clickNavigation = this.clickNavigation.bind(this)
  }
  clickNavigation(newUrl) {
    const location = history.location
    console.log(location.pathname, newUrl)
    if (location.pathname !== newUrl) {
      history.push({
        pathname: `${newUrl}`,
      })
      this.setState({
        redirectNavigation: newUrl,
      })
    }
  }

  render() {
    return (
      <FooterDiv>
        <WidthHolder>
          <FooterNavigation>
            <NavButton /*onClick={this.clickNavigation.bind(this,'/contact')}*/><a href="/contact">Kontakt</a></NavButton>
            <NavButton /*onClick={this.clickNavigation.bind(this,'/who-we-are')}*/><a href="/contact">Om oss</a></NavButton>
          </FooterNavigation>
          <SocialIcon src={logo}/>
          <FooterSocials>
            <Company>Kamafai KB | 969783-4589
              <br/> <a href="//goo.gl/maps/rj3eweXdju6woxwg7">Södra Förstadsgatan 36B | Malmö</a></Company>
          </FooterSocials>
        </WidthHolder>
        {this.state.redirectNavigation && <Redirect to={`${this.state.redirectNavigation}`} />}
      </FooterDiv>
    )
  }
}

export default Footer
