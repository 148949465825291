import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import HomeLayout from './containers/Home'
import WhoWeAre from './containers/WhoWeAre'
import WhatWeDo from './containers/WhatWeDo'
import Contact from './containers/Contact'
import Projects from './containers/Projects'
import MarkoKosmacCV from './containers/MarkoKosmacCV'

import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/who-we-are" render={WhoWeAre} />
      <Route path="/what-we-do" render={WhatWeDo} />
      <Route path="/contact" render={Contact} />
      <Route path="/projects" render={Projects} />
      <Route path="/marko-kosmac" render={MarkoKosmacCV} />
      <Route path="/" render={HomeLayout} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
)

serviceWorker.unregister();
