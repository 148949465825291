import React from 'react'
import Header from "./Header";
import Footer from "./Footer";
import styled from "styled-components";
import whoWeAreImageMarko from '../assets/marko-who-we-are.png'
import whoWeAreImageAlex from '../assets/alex-who-we-are.png'

const IntroSection = styled.div`
  margin: 4rem auto;
  width: 100%;
  min-height: 78vh;
`

const WidthHolder = styled.div`
  max-width: 1280px;
  width: 90%;
  margin: 10rem auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  
  @media only screen and (max-width: 768px) {
    flex-direction: column;  
    margin-top: 6rem;
  }
`

const LeftSection = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: 6rem auto 4rem 0;
  flex-direction: row;
  
  @media only screen and (max-width: 768px) {
    margin: 1rem 0 0 0;
  }
`

const RightSection = styled.div`
  width: 100%;
  max-width: 40rem;
  margin: 6rem 0 4rem auto;
  padding-left: 5rem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const Title = styled.h1`
  font-size: 3rem;
  
  @media only screen and (max-width: 768px) {

    text-align: center;

  }
`

const Content = styled.p`
  font-size: 1.125rem;
  max-width: 40rem;
  width: 100%;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;


  @media only screen and (max-width: 768px) {
    width: 100%;  
    padding: 0;
    border-left: 0;
    flex-direction: column;
    text-align: center;

  }
`

const CopyContainer = styled.div`
  width: 100%;
  line-height: 1.6;



  @media only screen and (max-width: 768px) {
    width: 100%;  
    padding: 0;
    border-left: 0;

  }
`


const WhoWeAreImage = styled.img`
  margin-top: -3rem;
  
  @media only screen and (max-width: 768px) {
    width: 80%;
    margin: 3rem auto 0;
  }
`

const OurValuesSection = styled.div`
  margin: 4rem auto;
  width: 100%;
`

const OurValuesTitle = styled.h4`
  font-size: 2rem;
`

const OurValuesBlock = styled.div`
  display: flex;
`

const OurValue = styled.div`
  
`

class WhoWeAreComponent extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="page">
        <Header/>
        <IntroSection>
          <WidthHolder>
            <Title>Om oss</Title>
              <ContentContainer>
                <CopyContainer>
                  <Content>KAMAFAI grundades 2017 av Marko och Alex och är en Malmö-baserad webbyrå. </Content>
                  <Content> Vi bygger hemsidor, mobilappar (Android och iOS), samt webb-applikationer.
                    Genom åren har vi jobbat med stora och små företag; globala bolag
                    och lokala affärsverksamheter.</Content>
                  <Content>Vi utvecklar det mesta mellan enklare Wordpress-hemsidor till mer krävande
                    lösningar.</Content>
                  <Content>Hör av dig om du behöver hjälp, vi träffas gärna över en kopp kaffe.</Content>
                  <Content>/Marko & Alex</Content>
                </CopyContainer>
                <WhoWeAreImage src={whoWeAreImageMarko}/>
              </ContentContainer>
          </WidthHolder>
        </IntroSection>
        <Footer/>
      </div>
    )
  }
}

export default WhoWeAreComponent
