import React from 'react'
import {Redirect} from 'react-router'
import ReactGA from 'react-ga'
import createHistory from 'history/createBrowserHistory'
import logo from '../assets/KamafaiLogoText.svg'
import mobileLogo from '../assets/KamafaiLogo.svg'
import styled from 'styled-components'

const history = createHistory()

const HeaderDiv = styled.div`
  background: #f2c94c;
  position: fixed;
  top: 0;
  width: 100%;
  height: 4rem;
  z-index: 10;
`

const WidthHolder = styled.div`
  width: 94%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0 1rem;
  }
`


const Logo = styled.img`
  cursor: pointer;
  height: 0.75rem;
  width: 6rem;
  padding-left: 1rem;
  position: relative; 
  margin-top: 1.7rem;
    

`

const NavigationRight = styled.div`
  background: #f2c94c;
  margin: 0 0 0 2rem;
  justify-content: flex-end;
  display: flex;
  width: 50%;
  transition: all 0.5s;
  
  @media only screen and (max-width: 768px) {
    position: fixed;
    width: 100%;
    top: 4rem;
    padding: 0 2rem;
    left: 0;
    right: 0;
    display: block;
    height: ${props => props.openNavigation ? '18rem' : '0'};
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
    
  }
`

const NavigationLeft = styled.div`
  background: #f2c94c;
  margin: 0;
  justify-content: flex-start;
  display: flex;
  width: 50%;
  transition: all 0.5s;

  
  @media only screen and (max-width: 768px) {
    position: fixed;
    width: 100%;
    top: 4rem;
    padding: 0 2rem;
    left: 0;
    right: 0;
    display: block;
    height: ${props => props.openNavigation ? '18rem' : '0'};
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
  }
`


const NavButton = styled.div`
  background: none;
  border: none;
  line-height: 4rem;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  margin-left: 1.5rem;
  white-space: nowrap;
  
  @media only screen and (min-width: 768px) {
    &:after {
      position: absolute;
      top: calc(100% - 0.125rem);
      left: 0;
      right: 0;
      margin: auto;
      width: 0
      height: 0.125rem;
      background: #000;
      content: "";
      opacity: 0;
      transition: width 0.3s, opacity 0.5s, transform 0.3s;
    }
    
    &:hover:after {
      opacity: 1;
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    height: 3.5rem;
    margin-left: 0;
          font-weight: normal;

    
    &:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
  }
`

const NavButtonRight = styled.div`
  display: none;
  border: none;
  line-height: 4rem;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  margin-left: 1.5rem;
  white-space: nowrap;
  
  @media only screen and (min-width: 768px) {
  
    &:after {
      position: absolute;
      top: calc(100% - 0.125rem);
      left: 0;
      right: 0;
      margin: auto;
      width: 0
      height: 0.125rem;
      background: #000;
      content: "";
      opacity: 0;
      transition: width 0.3s, opacity 0.5s, transform 0.3s;
    }
    
    &:hover:after {
      opacity: 1;
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    height: 3.5rem;
    margin-left: 0;
    display: block;
    
    &:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
  }
`

const MobileNavigation = styled.div`
  display: none;
  margin: 0.5rem 0.25rem 0;
  
  @media only screen and (max-width: 768px) {
    display: block;  
  }
`

/*const MobileLogo = styled.img`
    display: block;
    width: 1.5rem;
    margin-left: 1rem;
`*/

ReactGA.initialize('UA-146689815-1')

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectNavigation: '',
      MobileOpen: false,
    }
    this.clickMobileOpenNav = this.clickMobileOpenNav.bind(this)
    this.clickNavigation = this.clickNavigation.bind(this)
    this.runOnScroll = this.runOnScroll.bind(this)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.runOnScroll.bind(this))
  }

  clickNavigation(newUrl) {
    const location = history.location
    console.log(location.pathname, newUrl)
    if (location.pathname !== newUrl) {
      history.push({
        pathname: `${newUrl}`,
      })
      this.setState({
        redirectNavigation: newUrl,
      })
    }
  }

  clickMobileOpenNav() {
    this.setState(prevState => ({ MobileOpen: !prevState.MobileOpen }))

    //!this.state.MobileOpen && window.addEventListener('scroll', this.runOnScroll.bind(this))
  }

  runOnScroll(evt) {
    this.clickMobileOpenNav()
    window.removeEventListener('scroll', this.runOnScroll.bind(this))
  }



  render() {
    return (
      <HeaderDiv>
        <WidthHolder>
          <NavigationLeft openNavigation={this.state.MobileOpen}>
            <NavButton onClick={this.clickNavigation.bind(this,'/')}>START</NavButton>
            <NavButton onClick={this.clickNavigation.bind(this,'/what-we-do')}>TJÄNSTER</NavButton>
            <NavButton onClick={this.clickNavigation.bind(this,'/projects')}>VÅRA PROJEKT</NavButton>
          </NavigationLeft>


          <Logo onClick={this.clickNavigation.bind(this,'/')} src={logo} />

          <MobileNavigation className={this.state.MobileOpen ? 'menu-opened' : '' }>
            <div className="burger-container" onClick={this.clickMobileOpenNav}>
              <div className="burger">
                <div className="bar topBar"/>
                <div className="bar btmBar"/>
              </div>
            </div>
          </MobileNavigation>

          <NavigationRight openNavigation={this.state.MobileOpen}>
            <NavButtonRight onClick={this.clickNavigation.bind(this,'/')}>START</NavButtonRight>
            <NavButtonRight onClick={this.clickNavigation.bind(this,'/what-we-do')}>TJÄNSTER</NavButtonRight>
            <NavButtonRight onClick={this.clickNavigation.bind(this,'/projects')}>VÅRA PROJEKT</NavButtonRight>
            <NavButton onClick={this.clickNavigation.bind(this,'/who-we-are')}>OM OSS</NavButton>
            <NavButton onClick={this.clickNavigation.bind(this,'/contact')}>KONTAKT</NavButton>
          </NavigationRight>
        </WidthHolder>
        {this.state.redirectNavigation && <Redirect to={`${this.state.redirectNavigation}`} />}
      </HeaderDiv>
    )
  }
}

export default Header
