import React from 'react'
import Header from "./Header";
import Footer from "./Footer";
import styled from "styled-components";
import SonyImage from '../assets/projects/sony.png'
import TelenorImage from '../assets/projects/telenor.png'
import ComsysImage from '../assets/projects/comsys.svg'
import DEImage from '../assets/projects/daily-elegance.png'
import EMImage from '../assets/projects/8mood.png'
import HappyEarsImage from '../assets/projects/happyears.png'
import MediaEvolutionImage from '../assets/projects/media-evolution.png'
import MobiGoinImage from '../assets/projects/mobigoin.png'
import NazarImage from '../assets/projects/nazar.jpeg'
import WunderchefImage from '../assets/projects/wunderchef.png'
import AngularImage from '../assets/projects/angular.svg'
import IosImage from '../assets/projects/ios.svg'
import TheConferenceImage from '../assets/projects/theconference.png'
import DanskeImage from '../assets/projects/danske.png'
import Css3Image from '../assets/projects/css3.png'
import ReactImage from '../assets/projects/react.png'
import JSImage from '../assets/projects/js.png'
import NodeImage from '../assets/projects/node.png'
import AWSImage from '../assets/projects/aws.png'
import PostgresImage from '../assets/projects/postgres.png'
import AndroidImage from '../assets/projects/android.png'
import Html5Image from '../assets/projects/html5.png'
import PhpImage from '../assets/projects/php.png'
import WordpressImage from '../assets/projects/wordpress.svg'
import MysqlImage from '../assets/projects/mysql.svg'
import ApacheImage from '../assets/projects/apache.svg'
import JenkinsImage from '../assets/projects/jenkins.svg'
import HerokuImage from '../assets/projects/heroku.svg'
import JoomlaImage from '../assets/projects/joomla.png'
import Typo3Image from '../assets/projects/typo3.png'
import ReportexImageSwap from '../assets/projects/reportexgif.gif'
import CriotiveImageSwap from '../assets/projects/criotive.gif'
import TelenorImageSwap from '../assets/projects/telenor.gif'
import ComsysImageSwap from '../assets/projects/reportexgif.gif'
import DeImageSwap from '../assets/projects/daily-elegance.gif'
import EmImageSwap from '../assets/projects/eightmood.gif'
import HappyEarsImageSwap from '../assets/projects/reportexgif.gif'
import MobiGoinImageSwap from '../assets/projects/reportexgif.gif'
import NazarImageSwap from '../assets/projects/reportexgif.gif'
import TheConferenceImageSwap from '../assets/projects/reportexgif.gif'
import MediaEvolutionImageSwap from '../assets/projects/reportexgif.gif'
import WunderchefImageSwap from '../assets/projects/wunderchef.gif'
import DotNet from '../assets/projects/dotnet.png'
import '../Projects.css';
import {Helmet} from "react-helmet";

const IntroSection = styled.div`
  margin-top: 4rem;
  width: 100%;
  min-height: 85vh;
`

const WidthHolder = styled.div`
  max-width: 1280px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => props.column ? 'column' : 'row'}
  
  @media only screen and (max-width: 768px) {
    flex-direction: column;  


  }
`

const Title = styled.h1`
  margin-top: 8rem;
  width: 100%;
  font-size: 3rem;
  margin-bottom: 3rem;
  display: block; 
  
  @media only screen and (max-width: 768px) {
    width: 100%;  
    margin-bottom: 0;
    margin-top: 3rem;
    text-align: center;
  }
`

const Content = styled.p`
  font-size: 1.125rem;
  width: 100%;
  
  @media only screen and (max-width: 768px) {
    width: 100%;  
    text-align: center;

`


const ProjectList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
  flex-direction: column; 
`

const ProjectItemLeft = styled.div`
    padding-top: 8rem;
    padding-bottom: 8rem;
    display: flex;
    margin: 0;
    max-height: 40rem;
    

    @media only screen and (max-width: 768px) {
      width: 100%;  
      flex-direction: column;
      padding: 0;
      margin-top: 2rem;
      margin-bottom: 2rem;

    }
`

const ProjectItemLeftFirst = styled.div`
    padding-top: 1rem;
    padding-bottom: 8rem;
    display: flex;
    margin: 0;
    max-height: 40rem;
    

    @media only screen and (max-width: 768px) {
      width: 100%;  
      flex-direction: column;
      padding: 0;
      margin-top: 2rem;
      margin-bottom: 2rem;

    }
`

const ProjectItemRight = styled.div`
  padding-top: 8rem;
  padding-bottom: 8rem;
  display: flex;
  margin: 0;
  flex-flow: row-reverse;
  max-height: 40rem;
  
  @media only screen and (max-width: 768px) {
      width: 100%;  
      flex-direction: column;
      padding: 0;
      margin-top: 2rem;
      margin-bottom: 2rem;
  }
`

const ProjectImageTechnologiesContainer = styled.div`
    display: inherit;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem;
    flex-basis: auto;


  
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const ProjectImageTechnologies = styled.img`
    padding: 1rem;
    box-sizing: border-box;
    object-fit: contain;
    max-width: 20%;
    min-width: 20%;
    max-height: 8rem;
  
  @media only screen and (max-width: 768px) {
    box-sizing: border-box;
  }
`


const ProjectTextContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 4rem;
  width: 50%;
  border-left: 2px solid rgb(242, 201, 76);
  box-sizing: border-box;


  @media only screen and (max-width: 768px) {
    width: 100%;  
    padding: 0;
    border-left: 0;
    margin-bottom: 2rem;


  }
`

const ProjectTextContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 4rem;
  width: 50%;
  border-right: 2px solid rgb(242, 201, 76);
  box-sizing: border-box;


  @media only screen and (max-width: 768px) {
    width: 100%;  
    padding: 0;
    border-right: 0;
    margin-bottom: 2rem;


  }
`

const ProjectName = styled.div`
  text-transform: uppercase;
  margin-bottom: 0.75rem;
  
  @media only screen and (max-width: 768px) {
  padding: 2rem 0 0 0;
  text-align: center;
  }
`

const ProjectTitle = styled.h3`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #000

  
  @media only screen and (max-width: 768px) {
  padding: 0; 
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  }
  
  a {
    text-decoration: none;
    position: relative;
    color: #000
    font-size: 2rem;
      
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #000;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all .5s cubic-bezier(1,.25,0,.75) 0s;
    transition: all .5s cubic-bezier(1,.25,0,.75) 0s;
    }
    
    &:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    }
`


const HorizontalLineRight = styled.div`
    display: none;
    
    @media only screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    content: "";
    display: block;
    height: 0.15rem;
    width: 1rem;
    background-color: rgb(0, 0, 0);
    display: block;
  }
`

const HorizontalLineLeft = styled.div`
    content: "";
    display: block;
    height: 0.15rem;
    width: 1rem;
    background-color: rgb(0, 0, 0);
    margin: 1rem 0 0 1.5rem;
    
    @media only screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
    
`

const ProjectDesc = styled.p`
  font-size: 1rem;
  margin-bottom: 2.5rem;
  line-height: 1.6;

  
  @media only screen and (max-width: 768px) {
  text-align: center;
  padding: 1rem;
  margin: auto;
  
  }
  
    a {
    text-decoration: underline;
    position: relative;
    color: #000
    

`

const BreakingLine = styled.div`
  transform: rotate(${props => props.tilt === 'down' ? '3' : '-3'}deg);
  border-bottom: 2px solid #f2c94c;
  padding: 0

  
  @media only screen and (max-width: 768px) {
  display: none;
  }
  
`

class WhoWeAreComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sonyReportexImage: SonyImage,
      sonyCriotiveImage: SonyImage,
      telenorImage: TelenorImage,
      emImage: EMImage,
      mediaEvolutionImage: MediaEvolutionImage,
      deImage: DEImage,
      comsysImage: ComsysImage,
      happyEarsImage: HappyEarsImage,
      mobigoinImage: MobiGoinImage,
      theConferenceImage: TheConferenceImage,
      nazarImage: NazarImage,
      wunderchefImage: WunderchefImage,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    document.addEventListener("scroll", this.swapImage.bind(this), true)
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.swapImage.bind(this), false)
  }

  swapImage () {
    if(window.innerWidth > 768) {
      const projectImages = document.getElementsByClassName('project-image');
      Array.prototype.map.call(projectImages, element => {
        const elementBounding = element.getBoundingClientRect()
        if (elementBounding.y < 300) {
          const swapName = element.firstChild.getAttribute('swap-name');
          const swapImage = element.firstChild.getAttribute('swap-image');
          this.setState({
            [swapName]: swapImage
          })
        }
      })
    }
  }

  render() {
    return (
      <div className="page" classId="page-height">
        <Helmet>
          <title>Portfolio</title>
          <meta name="keywords" content="Javascript, HTML, CSS, React, Web Audio API, Redux, Webpack, Postcss/Autoprefixer,
                   Karma/Chai, AWS S3, Travis CI och LESS, NodeJS, AWS ELB, Express, RestAPI, .NET, PostgreSQL"/>
        </Helmet>
        <Header/>

        <IntroSection>
          <WidthHolder column={true}>
            <Title>Våra projekt</Title>
            <Content>Ta en titt på våra projekt nedan.
              <br/><br/>Vi har byggt många hemsidor och mobilappar genom åren.
            </Content>
            <ProjectList>

              <ProjectItemLeftFirst>
                <div className="project-image">
                  <img
                      src={this.state.sonyReportexImage}
                      swap-name="sonyReportexImage"
                      swap-image={ReportexImageSwap}
                      className="img-first"
                      alt="Sony Reportex"
                  />
                </div>
                <ProjectTextContainerRight>
                  <ProjectName>Projekt: Reportex</ProjectName>
                  <ProjectTitle><a href="//reportex.io" target="_blank">SONY MOBILE</a></ProjectTitle>
                  <ProjectDesc>Reportex är ett verktyg som möjliggör ljudredigeringar i ett textbaserad program.
                    Programmet transkriberar ljudfiler automatiskt och synkroniserar transkriberingen till ljudet så att
                    man enkelt kan redigera genom att klippa, klistra eller ta bort ord.
                    <br/> <br/>
                    Samarbete med utvecklare på Sony Mobile, Lund.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={ReactImage}/>
                    <ProjectImageTechnologies src={NodeImage}/>
                    <ProjectImageTechnologies src={AWSImage}/>
                    <ProjectImageTechnologies src={JenkinsImage}/>
                    <ProjectImageTechnologies src={PostgresImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerRight>
              </ProjectItemLeftFirst>
              <BreakingLine tilt='up'/>

              <ProjectItemRight>
                <div className="project-image">
                  <img
                      src={this.state.telenorImage}
                      swap-name="telenorImage"
                      swap-image={TelenorImageSwap}
                      className="img-first"
                      alt="Telenor"
                  />
                </div>
                <ProjectTextContainerLeft>
                  <ProjectTitle><a href="//telenor.dk/erhverv/" target="_blank">TELENOR</a></ProjectTitle>
                  <ProjectDesc>Vi implementerade designförbättringar och adderade
                     ny funktionalitet på företagssidan på Telenor.dk. Vi utvecklade
                    även <a href="//https://www.telenor.dk/erhverv/selvbetjening/login/">'OneScreen Business'</a>, en
                    inloggningsportal för företagskunder.
                    <br/><br/>Samarbete med utvecklare på Telenor i Köpenhamn, Danmark.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={ReactImage}/>
                    <ProjectImageTechnologies src={NodeImage}/>
                    <ProjectImageTechnologies src={AWSImage}/>
                    <ProjectImageTechnologies src={PostgresImage}/>
                    <ProjectImageTechnologies src={DotNet}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerLeft>
              </ProjectItemRight>
              <BreakingLine tilt='down'/>

              <ProjectItemLeft>
                <div className="project-image">
                  <img
                      src={DanskeImage}
                      /*swap-name="sonyCriotiveImage"
                      swap-image={CriotiveImageSwap}*/
                      className="img-first"
                      alt="Danske Bank"
                  />
                </div>
                <ProjectTextContainerRight>
                  <ProjectTitle><a href="//danskebank.dk/" target="_blank">DANSKE BANK</a></ProjectTitle>
                  <ProjectDesc>Front-end webbutveckling på ett internt projekt. Slutprodukt är en web applikation för
                    bankens anställda.
                    <br/> <br/>
                    Samarbete med utvecklare på Danske Bank, Köpenhamn.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerRight>
              </ProjectItemLeft>
              <BreakingLine tilt='up'/>

              <ProjectItemRight>
                <div className="project-image">
                  <img
                      src={this.state.sonyCriotiveImage}
                      swap-name="sonyCriotiveImage"
                      swap-image={CriotiveImageSwap}
                      className="img-first"
                      alt="Sony Criotive"
                  />
                </div>
                <ProjectTextContainerLeft>
                  <ProjectName>Projekt: Criotive</ProjectName>
                  <ProjectTitle><a href="//sony.com" target="_blank">SONY MOBILE</a></ProjectTitle>
                  <ProjectDesc>En <a href="//play.google.com/store/apps/details?id=com.criotive.access&hl=en">Android mobilapp</a> som
                    tilldelar och hanterar behörigheter till elektroniska lås via molnet. Systemet använder krypterad data
                    och enheter som t.ex. kort eller wearable som kopplas till appen.
                    <br/> <br/>
                    Samarbete med utvecklare på Sony Mobile, Lund.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={ReactImage}/>
                    <ProjectImageTechnologies src={NodeImage}/>
                    <ProjectImageTechnologies src={AWSImage}/>
                    <ProjectImageTechnologies src={PostgresImage}/>
                    <ProjectImageTechnologies src={JenkinsImage}/>
                    <ProjectImageTechnologies src={AndroidImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerLeft>
              </ProjectItemRight>
              <BreakingLine tilt='down'/>

              <ProjectItemLeft>
                <div className="project-image">
                  <img
                      src={this.state.theConferenceImage}
                      /*swap-name="theConferenceImage"
                      swap-image={TheConferenceImageSwap}*/
                      className="img-first"
                      alt="The Conference"
                  />
                </div>


                <ProjectTextContainerRight>
                  <ProjectName>Projekt: 2020</ProjectName>
                  <ProjectTitle><a href="//2020.theconference.se/" target="_blank">THE CONFERENCE</a></ProjectTitle>
                  <ProjectDesc>The Conference är en årlig konferens och festival som anordnas i Malmö
                    av <a href="//mediaevolution.se">Media Evolution</a> och utforskar komplexitet i en digital värld.
                    <br/><br/>
                    Vi utvecklade årets upplaga av hemsidan, genom att implementera ny design och uppdatera
                    funktionalitet i back-end och admin.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={NodeImage}/>
                    <ProjectImageTechnologies src={AWSImage}/>
                    <ProjectImageTechnologies src={PostgresImage}/>
                    <ProjectImageTechnologies src={HerokuImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerRight>
              </ProjectItemLeft>
              <BreakingLine tilt='up'/>

              <ProjectItemRight>
                <div className="project-image">
                  <img
                      src={this.state.mediaEvolutionImage}
                      /*swap-name="mediaEvolutionImage"
                      swap-image={MediaEvolutionImageSwap}*/
                      className="img-first"
                      alt="Media Evolution"
                  />
                </div>

                <ProjectTextContainerLeft>
                  <ProjectTitle><a href="//mediaevolution.se/" target="_blank">MEDIA EVOLUTION</a></ProjectTitle>
                  <ProjectDesc>Media Evolution är ett 'community-kluster' som består av 400 organisationer
                    och främjar innovation och tillväxt för den digitala industrin i södra Sverige.
                    <br/><br/>

                    Vi uppdaterade design och adderade nya funktioner för back-end och admin.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={MysqlImage}/>
                    <ProjectImageTechnologies src={PhpImage}/>
                    <ProjectImageTechnologies src={ApacheImage}/>
                    <ProjectImageTechnologies src={WordpressImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerLeft>
              </ProjectItemRight>
              <BreakingLine tilt='down'/>

              <ProjectItemLeft>
                <div className="project-image">
                  <img
                      src={this.state.deImage}
                      swap-name="deImage"
                      swap-image={DeImageSwap}
                      className="img-first"
                      alt="Daily Elegance"
                  />
                </div>
                <ProjectTextContainerRight>
                  <ProjectTitle><a href="//dailyelegance.se/" target="_blank">DAILY ELEGANCE</a></ProjectTitle>
                  <ProjectDesc>Daily Elegance är ett mode- och livsstilsvarumärke för kvinnor.

                    <br/><br/>
                    Hemsida byggdes med hjälp av ett Wordpress-tema och webshop-integration med Klarna som betallösning.
                    Vi kopplade även ihop hemsidan med Pyramid affärssystem för order, lager och redovisning.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={AWSImage}/>
                    <ProjectImageTechnologies src={MysqlImage}/>
                    <ProjectImageTechnologies src={PhpImage}/>
                    <ProjectImageTechnologies src={ApacheImage}/>
                    <ProjectImageTechnologies src={WordpressImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerRight>
              </ProjectItemLeft>
              <BreakingLine tilt='up'/>

              <ProjectItemRight>
                <div className="project-image">
                  <img
                      src={this.state.comsysImage}
                      /*swap-name="comsysImage"
                      swap-image={ComsysImageSwap}*/
                      className="img-first"
                      alt="Comsys"
                  />
                </div>
                <ProjectTextContainerLeft>
                  <ProjectName>Projekt: Online Sizing Tool</ProjectName>
                  <ProjectTitle><a href="//sizing.adfpowertuning.com/" target="_blank">COMSYS</a></ProjectTitle>
                  <ProjectDesc>Comsys är ett cleantech-bolag i Lund. Sedan starten 2001 har de levererat lösningar till
                    5 000 kunder i 50 länder.

                    <br/><br/>
                    Vi konverterade ett omfattande och komplext Excel-dokument med dussintals funktioner till
                    ett användningsbart verktyg på webben för företagets sälj- och marknadsavdelning.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={ReactImage}/>
                    <ProjectImageTechnologies src={NodeImage}/>
                    <ProjectImageTechnologies src={AWSImage}/>
                    <ProjectImageTechnologies src={JenkinsImage}/>
                    <ProjectImageTechnologies src={PostgresImage}/>
                    <ProjectImageTechnologies src={JoomlaImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerLeft>
              </ProjectItemRight>
              <BreakingLine tilt='down'/>

              <ProjectItemLeft>
                <div className="project-image">
                  <img
                      src={this.state.happyEarsImage}
                      /*swap-name="happyEarsImage"
                      swap-image={HappyEarsImageSwap}*/
                      className="img-first"
                      alt="Happy Ears"
                  />
                </div>
                <ProjectTextContainerRight>
                  <ProjectTitle><a href="//happyears.co/" target="_blank">HAPPY EARS</a></ProjectTitle>
                  <ProjectDesc>Happy Ears är ett svenskt bolag som designar och tillverkar återanvändningsbara öronproppar.

                    <br/><br/>
                    Vi konverterade deras nuvarande sida till ett CMS-system med admin-verktyg.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={AWSImage}/>
                    <ProjectImageTechnologies src={MysqlImage}/>
                    <ProjectImageTechnologies src={PhpImage}/>
                    <ProjectImageTechnologies src={WordpressImage}/>
                    <ProjectImageTechnologies src={ApacheImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerRight>
              </ProjectItemLeft>
              <BreakingLine tilt='up'/>

              <ProjectItemRight>
                <div className="project-image">
                  <img
                      src={this.state.mobigoinImage}
                      /*swap-name="mobigoinImage"
                      swap-image={MobiGoinImageSwap}*/
                      className="img-first"
                      alt="Mobigoing"
                  />
                </div>
                <ProjectTextContainerLeft>
                  <ProjectTitle><a href="//mobigoing.eu/" target="_blank">MOBIGOIN</a></ProjectTitle>
                  <ProjectDesc>Mobigoin är ett EU-finansierat projekt som ämnar att stötta Europeiska startups och SMEs.
                    <br/><br/>
                    Enkel hemsida med ett Wordpress-tema.

                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={AWSImage}/>
                    <ProjectImageTechnologies src={MysqlImage}/>
                    <ProjectImageTechnologies src={WordpressImage}/>
                    <ProjectImageTechnologies src={ApacheImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerLeft>
              </ProjectItemRight>
              <BreakingLine tilt='down'/>

              <ProjectItemLeft>
                <div className="project-image">
                  <img
                      src={this.state.emImage}
                      swap-name="emImage"
                      swap-image={EmImageSwap}
                      className="img-first"
                      alt="Eightmood"
                  />
                </div>

                <ProjectTextContainerRight>
                  <ProjectTitle><a href="//eightmood.com" target="_blank">EIGHTMOOD</a></ProjectTitle>
                  <ProjectDesc>Eightmood är ett svenskt bolag som designar och tillverkar textil- och accessoarer.
                    <br/><br/>
                    Designnyansering och nya funktioner på existerande Wordpress-hemsida.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={AWSImage}/>
                    <ProjectImageTechnologies src={MysqlImage}/>
                    <ProjectImageTechnologies src={PhpImage}/>
                    <ProjectImageTechnologies src={ApacheImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerRight>
              </ProjectItemLeft>
              <BreakingLine tilt='up'/>


              <BreakingLine tilt='down'/>

              {/*<ProjectItemRight>
                <div className="project-image">
                  <img
                      src={this.state.comsysImage}
                      swap-name="comsysImage"
                      swap-image={ComsysImageSwap}
                      className="img-first"
                      alt="Comsys"
                  />
                </div>
                <ProjectTextContainerLeft>
                  <ProjectName>Projekt: Online Log Analyzer</ProjectName>
                  <ProjectTitle>Comsys</ProjectTitle>
                  <HorizontalLineLeft></HorizontalLineLeft>
                  <ProjectDesc>Web solution with CMS implementation. Web solution with CMS implementation.
                    Web solution with CMS implementation.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={ReactImage}/>
                    <ProjectImageTechnologies src={NodeImage}/>
                    <ProjectImageTechnologies src={AWSImage}/>
                    <ProjectImageTechnologies src={JenkinsImage}/>
                    <ProjectImageTechnologies src={PostgresImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerLeft>
              </ProjectItemRight>
              <BreakingLine tilt='down'/>*/}

              <ProjectItemRight>
                <div className="project-image">
                  <img
                      src={this.state.wunderchefImage}
                      swap-name="wunderchefImage"
                      swap-image={WunderchefImageSwap}
                      className="img-first"
                      alt="Wunderchef"
                  />
                </div>
                <ProjectTextContainerLeft>
                  <ProjectTitle><a href="//wunderchef.co" target="_blank">WUNDERCHEF</a></ProjectTitle>
                  <ProjectDesc>Wunderchef är en matplattform där användare kan sälja sin hemlagade mat.

                    <br/><br/>Utveckling av hemsida i Wordpress och mobilappar för iOS och Android.
                  </ProjectDesc>
                  <ProjectImageTechnologiesContainer>
                    <ProjectImageTechnologies src={Html5Image}/>
                    <ProjectImageTechnologies src={Css3Image}/>
                    <ProjectImageTechnologies src={JSImage}/>
                    <ProjectImageTechnologies src={NodeImage}/>
                    <ProjectImageTechnologies src={AWSImage}/>
                    <ProjectImageTechnologies src={PostgresImage}/>
                    <ProjectImageTechnologies src={AndroidImage}/>
                    <ProjectImageTechnologies src={IosImage}/>
                    <ProjectImageTechnologies src={AngularImage}/>
                  </ProjectImageTechnologiesContainer>
                </ProjectTextContainerLeft>
              </ProjectItemRight>


            </ProjectList>
          </WidthHolder>
        </IntroSection>
        <Footer/>
      </div>
    )
  }
}

export default WhoWeAreComponent
