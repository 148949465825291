import React from 'react'
import Header from "./Header";
import Footer from "./Footer";
import styled from "styled-components";
import MarkoImage from '../assets/marko.png'
import AlexImage from '../assets/alex.png'
import Mailto from 'react-protected-mailto'

const IntroSection = styled.div`
  margin-top: 4rem;
  width: 100%;
  min-height: 85vh;
`

const WidthHolder = styled.div`
  max-width: 1280px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  
  
`

const Title = styled.h1`
  font-size: 2.5rem;
  
`

const Content = styled.span`
  font-size: 1.125rem;
  max-width: 40rem;
  width: 100%;
  
`



const LeftSection = styled.div`
  width: 100%;
  margin: 6rem auto 4rem 0;
  
  @media only screen and (max-width: 768px) {
    margin: 0;
    width: 100%;  
    text-align: center;
  }
`

const RightSection = styled.div`
  max-width: 40rem;
  width: 40%;
  margin: 6rem 0 4rem auto;
`

const SocialLinks = styled.div`
  background: #f2c94c;
  width: 100%;
  max-width: 19rem;
  margin: 6.5rem 0 4rem auto;
  padding: 1rem 1.5rem;
`

const ContactContainer = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  padding: 2rem 0;
  justify-content: space-between;
  flex-direction: row;
  
  
    @media only screen and (max-width: 768px) {
    flex-direction: column;

`

const ContactPersonMarko = styled.div`
  width: 100%;
  display: flex;
  padding: 2rem 0;
  flex-direction: column;
  border-right: 2px solid #f2c94c;

`

const ContactPersonAlex = styled.div`
  width: 100%;
  display: flex;
  padding: 2rem 0;
  flex-direction: column;
`


const ContactPersonImage = styled.img`
  margin-right: 1rem;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(242, 201, 76);
`

const ContactPersonDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media only screen and (max-width: 768px) {
    font-size: 0.75rem;  
    margin-left: auto;
    margin-right: auto;
    padding: 0; 
`

const ContactPersonDetailsAlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
    @media only screen and (max-width: 768px) {
    font-size: 0.75rem;  
    margin-left: auto;
    margin-right: auto;
    padding: 0; 
`

const ContactDetailRow = styled.div`
  margin: 0.5rem 0 0.25rem 0;
  
  @media only screen and (max-width: 768px) {
    font-size: 1rem;  
  }
`

const ContactDetailRowPhone = styled.span`
  margin: 0.5rem 0 0.25rem 0;
  text-decoration: none;
  height: auto;
  
  
  @media only screen and (max-width: 768px) {
    font-size: 1rem; 
  }
`



class HomeComponent extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="page">
        <Header/>
        <IntroSection>
          <WidthHolder>
            <LeftSection>
              <Title>Kontakt</Title>
              <Content>Du kan mejla eller ringa oss.</Content>
              <ContactContainer>
              <ContactPersonMarko>
                <ContactPersonImage src={MarkoImage}/>
                <ContactPersonDetails>
                  <ContactDetailRow><h2>Marko</h2></ContactDetailRow>
                  <ContactDetailRowPhone>☎️ +46 70 610 33 40</ContactDetailRowPhone>
                  <ContactDetailRow>&#128236; marko@kamafai.com</ContactDetailRow>
                </ContactPersonDetails>
              </ContactPersonMarko>

              <ContactPersonAlex>
                <ContactPersonImage src={AlexImage}/>
                <ContactPersonDetailsAlex>
                  <ContactDetailRow><h2>Alex</h2></ContactDetailRow>
                  <ContactDetailRowPhone>☎️ +46 724 515 888</ContactDetailRowPhone>
                  <ContactDetailRow>&#128236; alexander@kamafai.com</ContactDetailRow>
                </ContactPersonDetailsAlex>
              </ContactPersonAlex>
            </ContactContainer>
          </LeftSection>
        </WidthHolder>
      </IntroSection>
      <Footer/>
    </div>
    )
  }
}

export default HomeComponent
