import React from 'react'
import Header from "./Header";
import Footer from "./Footer";
import styled from "styled-components";
import KamafaiStack from '../assets/alex-what-we-do.png'
import {Helmet} from "react-helmet";
import IosImage from '../assets/projects/ios.svg'
import AndroidImage from '../assets/projects/android.png'
import Css3Image from '../assets/projects/css3.png'
import JSImage from '../assets/projects/js.png'
import Html5Image from '../assets/projects/html5.png'
import MagentoImage from '../assets/projects/magento.svg'
import WordpressImage from '../assets/projects/wordpress.svg'
import JoomlaImage from '../assets/projects/joomla.png'
import ReactImage from '../assets/projects/react.png'




const IntroSection = styled.div`
  margin-top: 4rem;
  width: 100%;
  min-height: 85vh;
`

const WidthHolder = styled.div`
  max-width: 1280px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => props.column ? 'column' : 'row'}
  
  @media only screen and (max-width: 768px) {
    flex-direction: column;  
  }
`

const LeftSection = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: 6rem auto 4rem 0;
  
  @media only screen and (max-width: 768px) {
    margin: 4rem 0 0 0;
    text-align: center;
  }
`

const RightSection = styled.div`
  width: 100%;
  max-width: 40rem;
  margin: 2rem 0 4rem auto;
  display: flex;
  align-items: center;
  
  @media only screen and (max-width: 768px) {
    margin: 0;
  }
`

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 3rem;
  
`

const Content = styled.p`
  font-size: 1.125rem;
  max-width: 40rem;
  width: 100%;
  line-height: 1.6;
  
    a {
    text-decoration: underline;
    position: relative;
    color: #000
     

`

const SkillItem = styled.img`
    margin: auto 0 auto auto;
    
    @media only screen and (max-width: 768px) {
        /*width: 100%;
        overflow: hidden;
        margin: auto;
        margin: 1rem 0 1rem 0;*/
        display: none;
        
  }
`

const ServicesItemContainer = styled.div`
    display: inherit;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem;
    flex-basis: auto;
  
  @media only screen and (max-width: 768px) {
}

`

const ServicesItem = styled.img`
    padding: 1rem;
    box-sizing: border-box;
    object-fit: contain;
    max-width: 20%;
    min-width: 20%;
    max-height: 8rem;
  
  @media only screen and (max-width: 768px) {
    box-sizing: border-box;
    max-width: 27%;

  }
`

const ServicesContainer = styled.div`
    display: flex;
    flex-direction: rows;
    width: 90%;
    max-width: 1280px;
    margin: auto;
    justify-content: space-between;
    
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin: auto;
        
`

const Websites = styled.div`
    display: flex;
    flex-direction: column
    max-width: 50%
    
    @media only screen and (max-width: 768px) {
        max-width: 100%;  
        padding: 0;
        border-left: 0;
        margin-bottom: 2rem;
`

const MobileApps = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 50%;
    border-left: 2px solid rgb(242, 201, 76);

    
    @media only screen and (max-width: 768px) {
        max-width: 100%;  
        padding: 0;
        border-left: 0;
        margin-bottom: 2rem;


`


const ContentServices = styled.div`
    width: auto;
    margin: auto;
    display: block;
    padding: 1rem;
    text-align: center;
    line-height: 1.6;

`

const TitleServices = styled.h2`
    font-size: 2rem;
    text-align: center;
`




class WhoWeAreComponent extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Tjänster</title>
          <meta name="keywords" content="hemsidor, hemsida, mobilapp, mobilapplikation, iOS, Android, Wordpress"/>
        </Helmet>
        <Header/>
        <IntroSection>
          <WidthHolder>
            <LeftSection>
              <Title>Våra tjänster</Title>
              <Content>Behöver du en hemsida eller mobilapp kan vi bygga den. Ta gärna en titt
                  på <a href="/projects">våra projekt</a>.
                  <br/>
              </Content>
                <Content>
                Nedan finner du mer information om våra tjänster.
                </Content>
            </LeftSection>
            <RightSection>
              <SkillItem src={KamafaiStack}/>
            </RightSection>

          </WidthHolder>
             <ServicesContainer>
                <Websites>
                    <TitleServices>Hemsidor</TitleServices>
                    <ContentServices>När vi bygger hemsidor gör vi det från grunden, eller genom att
                        använda populära CMS-system som t.ex. Wordpress, Joomla eller Magento.
                    </ContentServices>
                    <ServicesItemContainer>
                        <ServicesItem src={Html5Image}/>
                        <ServicesItem src={Css3Image}/>
                        <ServicesItem src={JSImage}/>

                    </ServicesItemContainer>
                </Websites>

                <MobileApps>
                    <TitleServices>Mobilappar</TitleServices>
                    <ContentServices>Vi bygger mobilappar för iOS och Android med hjälp av React Native. Vi kan även
                        hjälpa till med att publicera på App Store eller Google play.
                    </ContentServices>
                    <ServicesItemContainer>
                        <ServicesItem src={IosImage}/>
                        <ServicesItem src={AndroidImage}/>
                        <ServicesItem src={ReactImage}/>
                    </ServicesItemContainer>
                </MobileApps>

            </ServicesContainer>
        </IntroSection>
        <Footer/>
      </div>
    )
  }
}

export default WhoWeAreComponent
